// frontend/src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from './stores/authStore';

const ConnexionTS = () => import('./components/ConnexionTS.vue');
const DashboardTSa = () => import('./components/DashboardTSa.vue');
const PersonnesMoralesTS = () => import('./components/PersonnesMoralesTS.vue');
const TestComponent = () => import('./components/TestComponent.vue');

const routes = [
  { path: '/connexionTS', component: ConnexionTS, name: 'ConnexionTS' },
  { path: '/dashboardtsa', component: DashboardTSa, name: 'DashboardTSa', meta: { requiresAuth: true } },
  { path: '/personnesmoralesTS', component: PersonnesMoralesTS, name: 'PersonnesMoralesTS', meta: { requiresAuth: true } },
  { path: '/signin-oidc', component: ConnexionTS, name: 'SigninOidc' },
  { path: '/test', component: TestComponent, name: 'TestComponent' },
  { path: '/', redirect: { name: 'ConnexionTS' } },
  { path: '/:catchAll(.*)', name: 'NotFound', component: ConnexionTS } // Catch all route for 404
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Mettez à jour isAuthenticated à chaque navigation
  authStore.$patch({ isAuthenticated: !!localStorage.getItem('user-token') });

  // Si la route nécessite une authentification et que l'utilisateur n'est pas authentifié, rediriger vers la page de connexion
  if (to.matched.some(record => record.meta.requiresAuth) && !authStore.isAuthenticated) {
    next({ name: 'ConnexionTS' });
  } else {
    next();
  }
});

export default router;
