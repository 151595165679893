// frontend/src/config/msalConfig.js
import { PublicClientApplication } from '@azure/msal-browser';
import { reactive } from 'vue';

export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const graphScopes = {
  scopes: [`application://${process.env.VUE_APP_CLIENT_ID}/.default`]
};

export const state = reactive({
  isAuthenticated: false,
  user: null
});

export const myMSALObj = new PublicClientApplication(msalConfig);
