import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { useAuthStore } from './stores/authStore';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

const authStore = useAuthStore();
authStore.handleRedirect().then(() => {
  app.mount('#app');
}).catch((error) => {
  console.error('Erreur lors de la redirection:', error);
  app.mount('#app');
});
